import React from "react";
import { ContentSection, Media, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Layout from "@components/Shared/Templates/Layout";

type ESGHero = {
	title: string;
	subtitle: string;
	videoId: string;
	thumbnailUrl: string;
	infogramId: string;
	downloadLink: string;
	downloadLabel: string;
};

type ESGLeftRight = {
	imageSide: "left" | "right";
	background: "primary" | "secondary";
	title: string;
	description: string[];
	thumbnailUrl: string;
	videoId: string;
};

type ESGQuote = {
	title: string;
	description: string[];
	quote: string;
	author: string;
	authorTitle: string;
	imageUrl: string;
};

type ESGQuoteProps = {
	content: ESGQuote;
};

type LeftRightProps = {
	content: ESGLeftRight;
};

type ImageBlurbWithLinksProps = {
	imageUrl: string;
	title: string;
	blurb: {
		text: string;
		link?: string;
	}[];
};

const EmissionsIlluminatorContent: LanguageContent<ImageBlurbWithLinksProps> = {
	en: {
		title: "Emissions Illuminator",
		imageUrl: "/images/why-ryerson/ryerson-commitment/esg/emmissions-illuminator.jpg",
		blurb: [
			{
				text: `Ryerson strives to support our customers' success by providing greater visibility 
					into the carbon footprint of their supply chain with the Emissions Illuminator. 
					This unique tool allows users to estimate emissions produced by their metals supply 
					chain based on location, product, and shipping information. Users can compare scenarios 
					and download a PDF summary to help achieve sustainability goals. The Emissions Illuminator 
					is a digital solution designed to optimize supply chains and enhance sustainability efforts. 
					For more information, visit `,
			},
			{
				text: "https://illuminatorapp.ryerson.com/",
				link: "https://illuminatorapp.ryerson.com/",
			},
			{
				text: " or download the app from the iOS or Android App Stores.",
			},
		],
	},
	fr: {
		title: "Emissions Illuminator",
		imageUrl: "/images/why-ryerson/ryerson-commitment/esg/emmissions-illuminator.jpg",
		blurb: [
			{
				text: `Ryerson s’efforce de contribuer à la réussite de ses clients en leur offrant une meilleure visibilité sur l’empreinte carbone de leur chaîne d’approvisionnement grâce à Emissions Illuminator. Cet outil unique permet aux utilisateurs d’estimer les émissions produites par leur chaîne d’approvisionnement des métaux en fonction de l’emplacement, du produit et des renseignements sur l’expédition. Les utilisateurs peuvent comparer les scénarios et télécharger un résumé en format PDF pour les aider à atteindre leurs objectifs en matière de développement durable. Emissions Illuminator est une solution numérique conçue pour optimiser les chaînes d’approvisionnement et rehausser les efforts en matière de développement durable. Pour en savoir plus, visitez le site `,
			},
			{
				text: "https://illuminatorapp.ryerson.com/",
				link: "https://illuminatorapp.ryerson.com/",
			},
			{
				text: " ou téléchargez l’application à partir des magasins d’applications iOS ou Android.",
			},
		],
	},
	es: {
		title: "Emissions Illuminator",
		imageUrl: "/images/why-ryerson/ryerson-commitment/esg/emmissions-illuminator.jpg",
		blurb: [
			{
				text: `Ryerson busca contribuir al éxito de sus clientes permitiéndoles visualizar mejor la huella de carbono de su cadena de suministro con Emissions Illuminator. Esta herramienta única permite a los usuarios estimar las emisiones producidas por su cadena de suministro de metales en función de la ubicación, el producto y la información de envíos. Los usuarios pueden comparar situaciones y descargar un resumen en PDF para contribuir a alcanzar los objetivos de sostenibilidad. Emissions Illuminator es una solución digital diseñada para optimizar las cadenas de suministro y mejorar las iniciativas de sostenibilidad. Para más información, visite `,
			},
			{
				text: "https://illuminatorapp.ryerson.com/",
				link: "https://illuminatorapp.ryerson.com/",
			},
			{
				text: " o descargue la aplicación en las tiendas de aplicaciones de iOS o Android.",
			},
		],
	},
};

const ESGQuoteContent: LanguageContent<ESGQuote> = {
	en: {
		title: "Our Role as a Corporate Citizen",
		description: [
			"Ryerson takes pride in being a responsible corporate citizen, driven by dedication to our stakeholders. We demonstrate this commitment throughout our business, from ethical leadership and corporate governance to products that meet or exceed strict quality and compliance standards.",
			"Ethics and compliance are major contributors to the longevity of our business and pillars of our stakeholder relationships. In addition to our commitment to ethics and compliance, we champion accountability with a focus on transparency throughout our organization.",
		],
		quote: "Our mission is to consistently create great experiences for our stakeholders by delivering sustainably valuable metal solutions that build a better world.",
		author: "Edward J. Lehner",
		authorTitle: "President and Chief Executive Officer | Director",
		imageUrl: "/images/why-ryerson/ryerson-commitment/esg/eddie-lehner-portrait.jpg",
	},
	fr: {
		title: "Notre rôle en tant qu’entreprise citoyenne",
		description: [
			"Ryerson est fière d’être une entreprise citoyenne responsable, motivée par son dévouement envers ses parties prenantes. Nous démontrons cet engagement dans l’ensemble de nos activités, depuis le leadership éthique et la gouvernance d’entreprise jusqu’à nos produits qui respectent ou dépassent les normes rigoureuses de qualité et de conformité.",
			"L’éthique et la conformité sont des éléments essentiels de la longévité de notre entreprise, et constituent les piliers de nos relations avec nos parties prenantes. En plus de notre engagement en matière d’éthique et de conformité, nous favorisons la responsabilisation en mettant l’accent sur la transparence dans l’ensemble de notre entreprise.",
		],
		quote: "Notre mission consiste à créer constamment des expériences exceptionnelles pour nos parties prenantes en proposant des produits métallurgiques personnalisés de valeur durable afin de construire un monde meilleur.",
		author: "Edward J. Lehner",
		authorTitle: "Président et chef de la direction | Administrateur",
		imageUrl: "/images/why-ryerson/ryerson-commitment/esg/eddie-lehner-portrait.jpg",
	},
	es: {
		title: "Nuestro papel como ciudadano corporativo",
		description: [
			"Ryerson se enorgullece de ser un ciudadano corporativo responsable, impulsado por la dedicación a nuestras partes interesadas. Demostramos este compromiso en todo nuestro negocio, desde el liderazgo ético y el gobierno corporativo hasta los productos que cumplen o superan estrictos estándares de calidad y cumplimiento.",
			"La ética y el cumplimiento son importantes contribuyentes a la longevidad de nuestro negocio y pilares de nuestras relaciones con las partes interesadas. Además de nuestro compromiso con la ética y el cumplimiento, defendemos la responsabilidad con un enfoque en la transparencia en toda nuestra organización.",
		],
		quote: "Nuestra misión es crear de manera consistente experiencias excelentes para nuestras partes interesadas al proporcionar sustentablemente soluciones de metales valiosas para construir un mundo mejor.",
		author: "Edward J. Lehner",
		authorTitle: "Presidente y director general",
		imageUrl: "/images/why-ryerson/ryerson-commitment/esg/eddie-lehner-portrait.jpg",
	},
};

const LeftRightContent: LanguageContent<ESGLeftRight[]> = {
	en: [
		{
			imageSide: "right",
			background: "secondary",
			title: "Our People, Our Community",
			description: [
				"Ryerson is committed to the wellbeing, safety of our employees, as well as their engagement and growth. Having the right people in the right places is the cornerstone of creating a successful, ethical, and sustainable company.",
				"We view biased free and anti-discriminatory employee practices as fundamental. Put simply, offering equal opportunities is critical to attracting and retaining the right talent, and key to unlocking the potential of our workforce. We consistently look to cultivate and develop employee engagement with an eye toward the future.",
			],
			thumbnailUrl: "/images/why-ryerson/ryerson-commitment/esg/our-people-our-community.jpg",
			videoId: "ARIxsg5SjYo",
		},
		{
			imageSide: "left",
			background: "primary",
			title: "Our Environmental Responsibility",
			description: [
				"At Ryerson, we understand the responsibilities that come with processing and distributing over 2 million tons of metal annually and embrace the role of being an industry leader in minimizing deleterious environmental impacts.",
				"We actively integrate environmental initiatives throughout our operations and enable and assist our customers on their own paths to sustainability. By operating sustainably, and in an environmentally responsible way, we ensure that Ryerson will be able to continue serving our customers, employees, and investors for decades to come.",
			],
			thumbnailUrl:
				"/images/why-ryerson/ryerson-commitment/esg/our-environmental-responsibility.jpg",
			videoId: "U6ZtGana4_E",
		},
	],
	fr: [
		{
			imageSide: "right",
			background: "secondary",
			title: "Notre peuple, notre communauté",
			description: [
				"Ryerson s’engage envers le bien-être, la sécurité, l’engagement et la croissance de ses employés. Avoir les bonnes personnes au bon endroit est la pierre angulaire de la création d’une entreprise prospère, éthique et durable.",
				"Nous considérons les pratiques antidiscriminatoires et impartiales à l’égard du personnel comme des valeurs fondamentales. En termes simples, il est essentiel de souscrire au principe d’égalité d’accès à l’emploi pour attirer et retenir les bons talents, et pour libérer le potentiel de notre main-d’œuvre. Nous cherchons constamment à cultiver et à développer la mobilisation des employés avec un regard tourné vers l’avenir.",
			],
			thumbnailUrl: "/images/why-ryerson/ryerson-commitment/esg/our-people-our-community.jpg",
			videoId: "ARIxsg5SjYo",
		},
		{
			imageSide: "left",
			background: "primary",
			title: "Notre responsabilité envers l’environnement",
			description: [
				"Chez Ryerson, nous comprenons les responsabilités liées au traitement et à la distribution de plus de 2 millions de tonnes de métal par an et nous assumons notre rôle de chef de file de l’industrie dans la réduction des impacts environnementaux néfastes.",
				"Nous intégrons activement des initiatives environnementales dans toutes nos activités et aidons nos clients à parcourir leur propre chemin vers le développement durable. En adoptant une approche durable et respectueuse de l’environnement, nous nous assurons que Ryerson sera en mesure de continuer à servir nos clients, nos employés et nos investisseurs pendant les décennies à venir.",
			],
			thumbnailUrl:
				"/images/why-ryerson/ryerson-commitment/esg/our-environmental-responsibility.jpg",
			videoId: "U6ZtGana4_E",
		},
	],
	es: [
		{
			imageSide: "right",
			background: "secondary",
			title: "Nuestra gente, nuestra comunidad",
			description: [
				"Ryerson está comprometida con el bienestar y la seguridad de nuestros empleados, así como con su participación y desarrollo. Tener las personas correctas en el lugar adecuado es la piedra angular para crear una compañía exitosa, ética y sustentable.",
				"Vemos las prácticas laborales libres de prejuicios y antidiscriminatorias como fundamentales. En pocas palabras, ofrecer oportunidades equitativas es crítico para atraer y retener el talento correcto, y una clave para desbloquear el potencial de nuestra fuerza laboral. Buscamos de manera consistente cultivar y desarrollar la participación de los empleados con una visión hacia el futuro.",
			],
			thumbnailUrl: "/images/why-ryerson/ryerson-commitment/esg/our-people-our-community.jpg",
			videoId: "ARIxsg5SjYo",
		},
		{
			imageSide: "left",
			background: "primary",
			title: "Nuestra responsabilidad con el medio ambiente",
			description: [
				"En Ryerson, entendemos la responsabilidad que implica procesar y distribuir más de 2 millones de toneladas de metal anualmente y acogemos el papel de ser un líder de la industria en minimizar el impacto nocivo al medio ambiente.",
				"Integramos activamente iniciativas ambientales a través de nuestras operaciones y habilitamos y apoyamos a nuestros clientes en su trayecto hacia la sustentabilidad. Al operar de manera sustentable, y en una forma responsable con el medio ambiente, aseguramos que Ryerson podrá continuar sirviendo a nuestros clientes, empleados e inversionistas durante las décadas por venir.",
			],
			thumbnailUrl:
				"/images/why-ryerson/ryerson-commitment/esg/our-environmental-responsibility.jpg",
			videoId: "U6ZtGana4_E",
		},
	],
};

const ESGHeroContent: LanguageContent<ESGHero> = {
	en: {
		title: "At Ryerson, we believe that our success as a company starts with being a good corporate citizen.",
		subtitle:
			"Our Environmental, Social & Governance (ESG) report highlights our commitment to making meaningful progress in five key focus areas: biased free and anti-discriminatory employee practices, energy and emissions, talent and future workforce, circular economy, and data security.",
		videoId: "dKm3afjdZj4",
		thumbnailUrl: "/images/why-ryerson/ryerson-commitment/esg/hero-thumbnail-update.jpg",
		infogramId: "a83d7054-2882-4f7a-9485-e3cb1b17c179",
		downloadLink:
			"/static-assets/documents/pdfs/en/environmental-health-and-safety-certifications/Sustainability-Report-2024.pdf",
		downloadLabel: "Download Copy",
	},
	fr: {
		title: "Chez Ryerson, nous croyons que notre succès en tant qu’entreprise commence par être une bonne entreprise citoyenne.",
		subtitle:
			"Notre rapport environnemental, social et de gouvernance (ESG) souligne notre engagement à réaliser des progrès notables dans cinq domaines clés: les pratiques antidiscriminatoires et impartiales à l’égard du personnel; l’énergie et les émissions; les talents et la main-d’œuvre future; l’économie circulaire; et la sécurité des données.",
		videoId: "dKm3afjdZj4",
		thumbnailUrl: "/images/why-ryerson/ryerson-commitment/esg/hero-thumbnail-update.jpg",
		infogramId: "a83d7054-2882-4f7a-9485-e3cb1b17c179",
		downloadLink:
			"/static-assets/documents/pdfs/en/environmental-health-and-safety-certifications/Sustainability-Report-2024.pdf",
		downloadLabel: "Télécharger la copie",
	},
	es: {
		title: "En Ryerson, creemos que nuestro éxito como compañía comienza con ser un buen ciudadano corporativo.",
		subtitle:
			"Nuestro reporte medioambiental, social y de gobernanza (ESG) destaca nuestro compromiso de lograr un progreso significativo en cinco áreas clave de enfoque: prácticas laborales libres de prejuicios y antidiscriminatorias, energía y emisiones, talento y mano de obra futura, economía circular y seguridad de los datos.",
		videoId: "dKm3afjdZj4",
		thumbnailUrl: "/images/why-ryerson/ryerson-commitment/esg/hero-thumbnail-update.jpg",
		infogramId: "a83d7054-2882-4f7a-9485-e3cb1b17c179",
		downloadLink:
			"/static-assets/documents/pdfs/en/environmental-health-and-safety-certifications/Sustainability-Report-2024.pdf",
		downloadLabel: "Descargar Copiar",
	},
};

type ESGHeroProps = {
	content: ESGHero;
};

const InfogramContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding: 28px 48px;
	box-sizing: border-box;
	border-radius: 2px;
	text-align: center;
	margin-bottom: -280px;
	position: relative;
	z-index: 1;
`;

const DownloadButton = styled.div`
	display: inline-block;
	margin: 24px auto 0px auto;
	padding: 12px 16px;
	border-radius: 50px;
	text-align: center;
	width: auto;
	white-space: nowrap;
	height: auto;
`;

const Spacer = styled.div`
	height: 40px;
	display: block;
`;

type InfogrammerType = {
	infogramId: string;
};

const Infogrammer: React.FC<InfogrammerType> = ({ infogramId }) => {
	React.useEffect(() => {
		let t: any = "InfogramEmbeds";
		let d = document.getElementsByTagName("script")[0];
		if ((window as any)[t] && (window as any)[t].initialized)
			(window as any)[t].process && (window as any)[t].process();
		else if (!document.getElementById("infogram-async")) {
			var o = document.createElement("script");
			o.async = true;
			o.id = "infogram-async";
			o.src = "https://e.infogram.com/js/dist/embed-loader-min.js";
			if (d.parentNode) d.parentNode.insertBefore(o, d);
		}
	}, []);
	return (
		<div
			className="infogram-embed"
			data-id={infogramId}
			data-type="interactive"
			data-title={"2024 Sustainability Report"}
			css={css`
				max-width: 100%;
			`}
		></div>
	);
};

const ESGHeroComponent: React.FC<ESGHeroProps> = ({ content }) => {
	const { title, subtitle, videoId, thumbnailUrl, infogramId, downloadLink, downloadLabel } =
		content;
	const { theme } = useTheme();

	return (
		<>
			<ContentSection type="tertiary">
				<Spacer
					css={css`
						@media (max-width: ${theme.breakpoints.lg}) {
							height: 0px;
						}
					`}
				/>
				<Breadcrumb size="xs" type="tertiary" />
				<Media greaterThanOrEqual="lg">
					<Flex
						direction="row"
						justifyContent="space-between"
						css={css`
							margin-bottom: 80px;
							margin-top: 42px;
						`}
					>
						<FlexItem>
							<Typography
								css={css`
									margin-bottom: 60px;
									max-width: 440px;
								`}
								variant="h2"
								type="tertiary"
							>
								{title}
							</Typography>
							<Typography
								variant="div"
								css={css`
									max-width: 440px;
								`}
								type="tertiary"
								color={theme.colors.tertiary.header}
							>
								{subtitle}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								width: 620px;
								height: 360px;
							`}
						>
							<Video
								videoId={videoId}
								imageUrl={thumbnailUrl}
								buttonOrientation="left"
							/>
						</FlexItem>
					</Flex>
				</Media>
				<Media lessThan="lg">
					<Typography
						css={css`
							margin-bottom: 40px;
							margin-top: 16px;
						`}
						variant="h3"
						weight="normal"
						type="tertiary"
					>
						{title}
					</Typography>
					<Video videoId={videoId} imageUrl={thumbnailUrl} buttonOrientation="top" />
					<Typography
						variant="div"
						css={css`
							margin-top: 28px;
							margin-bottom: 48px;
						`}
						type="tertiary"
						color={theme.colors.tertiary.header}
					>
						{subtitle}
					</Typography>
				</Media>
				<InfogramContainer
					css={css`
						background-color: ${theme.colors.primary.darkGray};
						@media (max-width: ${theme.breakpoints.lg}) {
							padding: 16px 8px 44px 8px;
							margin-bottom: -140px;
						}
					`}
				>
					<Infogrammer infogramId={infogramId} />
					<Link to={downloadLink} target="_blank">
						<DownloadButton
							css={css`
								border: 2px solid ${theme.colors.primary.white};
							`}
						>
							<span
								css={css`
									display: inline-block;
									margin-right: 5px;
									vertical-align: middle;
								`}
							>
								<Icon
									icon="file-pdf"
									size="sm"
									color={theme.colors.primary.white}
								/>
							</span>
							<Typography
								variant="span"
								css={css`
									display: inline-block;
									vertical-align: middle;
								`}
								size="sm"
								color={theme.colors.primary.white}
							>
								{downloadLabel}
							</Typography>
						</DownloadButton>
					</Link>
				</InfogramContainer>
			</ContentSection>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary" vPadding="140px" />
			</Media>

			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="70px" />
			</Media>
		</>
	);
};

const ESGLeftRightComponent: React.FC<LeftRightProps> = ({ content }) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type={content.background} vPadding="80px">
					<Typography
						variant="h1"
						type={content.background}
						css={css`
							margin-bottom: 40px;
							text-align: ${content.imageSide === "left" ? "right" : "left"};
						`}
					>
						{content.title}
					</Typography>
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						{content.imageSide === "left" && (
							<FlexItem
								css={css`
									width: 600px;
									height: 360px;
								`}
							>
								<Video
									videoId={content.videoId}
									imageUrl={content.thumbnailUrl}
									buttonOrientation="right"
								/>
							</FlexItem>
						)}
						<Typography
							variant="div"
							css={css`
								width: 300px;
								height: auto;
								display: block;
							`}
						>
							{content.description.map((paragraph) => (
								<Typography
									variant="div"
									size="md"
									css={css`
										padding-bottom: 10px;
										padding-top: 10px;
									`}
									type={content.background}
								>
									{paragraph}
								</Typography>
							))}
						</Typography>
						{content.imageSide === "right" && (
							<FlexItem
								css={css`
									width: 600px;
									height: 360px;
								`}
							>
								<Video
									videoId={content.videoId}
									imageUrl={content.thumbnailUrl}
									buttonOrientation="left"
								/>
							</FlexItem>
						)}
					</Flex>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<Typography
					variant="div"
					css={css`
						margin-bottom: 40px;
						margin-top: 18px;
					`}
				>
					{content.description.map((paragraph) => (
						<Typography
							variant="div"
							size="md"
							css={css`
								padding-bottom: 10px;
								padding-top: 10px;
							`}
							type={content.background}
						>
							{paragraph}
						</Typography>
					))}
				</Typography>

				<Video
					videoId={content.videoId}
					imageUrl={content.thumbnailUrl}
					buttonOrientation="top"
				/>
				<Spacer
					css={css`
						height: 20px;
					`}
				/>
			</Media>
		</>
	);
};

const ESGImageBlurbWithLinkComponent: React.FC<ImageBlurbWithLinksProps> = ({
	imageUrl,
	title,
	blurb,
}) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" vPadding="80px">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<FlexItem
							css={css`
								width: 560px;
								height: 360px;
								background-image: url(${imageUrl});
								background-size: cover;
								background-position: center;
								background-repeat: no-repeat;
							`}
						/>
						<FlexItem
							css={css`
								width: calc(100% - 560px);
								max-width: 460px;
							`}
						>
							<Typography
								variant="h1"
								type="primary"
								css={css`
									margin-bottom: 40px;
								`}
							>
								{title}
							</Typography>
							<Typography
								variant="div"
								css={css`
									width: 100%;
									height: auto;
									display: block;
								`}
							>
								{blurb.map((b, index: number) => (
									<Typography key={index} variant="span" size="md" type="primary">
										{b.link ? (
											<Link to={b.link} target="_blank" gatsby={false}>
												{b.text}
											</Link>
										) : (
											b.text
										)}
									</Typography>
								))}
							</Typography>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<img
					css={css`
						width: 100%;
						height: auto;
						max-width: 560px;
						display: block;
						margin: 22px auto;
					`}
					src={imageUrl}
					alt={title}
				/>
				<Typography
					variant="div"
					css={css`
						margin-bottom: 40px;
					`}
				>
					{blurb.map((b, index: number) => (
						<Typography key={index} variant="span" size="md" type="primary">
							{b.link ? (
								<Link to={b.link} target="_blank" gatsby={false}>
									{b.text}
								</Link>
							) : (
								b.text
							)}
						</Typography>
					))}
				</Typography>
			</Media>
		</>
	);
};

const ESGQuoteComponent: React.FC<ESGQuoteProps> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary" vPadding="80px">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<FlexItem>
							<Typography
								variant="h1"
								css={css`
									max-width: 460px;
									margin-bottom: 34px;
								`}
							>
								{content.title}
							</Typography>
							<Typography
								variant="div"
								css={css`
									max-width: 360px;
								`}
							>
								{content.description.map((paragraph) => (
									<Typography
										variant="div"
										size="md"
										css={css`
											padding-bottom: 10px;
											padding-top: 10px;
										`}
									>
										{paragraph}
									</Typography>
								))}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								position: relative;
								width: 560px;
							`}
						>
							<img
								src={content.imageUrl}
								alt={content.author}
								css={css`
									width: 160px;
									height: auto;
									border-radius: 160px;
									position: absolute;
									right: 0px;
									top: 0px;
								`}
							/>
							<Typography
								variant="div"
								css={css`
									max-width: 480px;
									padding-left: 20px;
									padding-top: 100px;
									box-sizing: border-box;
									position: relative;
								`}
							>
								<Typography
									variant="span"
									css={css`
										position: absolute;
										top: 100px;
										left: 0px;
										font-size: 37.5px;
									`}
									size="lg"
									weight="bold"
									color={theme.colors.primary.header}
								>
									“
								</Typography>
								<Typography
									variant="h3"
									type="secondary"
									css={css`
										display: inline-block;
										letter-spacing: -2%;
										vertical-align: middle;
									`}
								>
									{content.quote}
									<Typography
										variant="span"
										css={css`
											display: inline-block;
											padding-top: 5px;
											font-size: 37.5px;
											vertical-align: middle;
										`}
										size="lg"
										weight="bold"
										color={theme.colors.primary.header}
									>
										”
									</Typography>
								</Typography>
							</Typography>
							<Typography
								variant="div"
								css={css`
									width: 100%;
									display: block;
									text-align: right;
								`}
							>
								<Typography
									variant="div"
									weight="bold"
									size="md"
									color={theme.colors.primary.header}
									css={css`
										margin-top: 20px;
										box-sizing: border-box;
										display: inline-block;
										text-align: left;
										width: auto;
										white-space: nowrap;
									`}
								>
									{content.author}
									<div
										css={css`
											display: block;
											width: 100%;
										`}
									></div>
									<Typography
										variant="div"
										weight="bold"
										type="primary"
										css={css`
											box-sizing: border-box;
											text-align: left;
											width: auto;
											display: inline-block;
											white-space: nowrap;
										`}
									>
										{content.authorTitle}
									</Typography>
								</Typography>
							</Typography>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<Spacer
					css={css`
						height: 15px;
					`}
				/>
				{content.description.map((paragraph) => (
					<Typography
						variant="div"
						size="md"
						css={css`
							padding-bottom: 10px;
							padding-top: 10px;
						`}
					>
						{paragraph}
					</Typography>
				))}
				<Typography
					variant="div"
					css={css`
						width: 100%;
						padding-left: 15px;
						padding-top: 20px;
						box-sizing: border-box;
						position: relative;
					`}
				>
					<Typography
						variant="span"
						css={css`
							position: absolute;
							top: 20px;
							left: 0px;
							font-size: 37.5px;
						`}
						size="xl"
						weight="bold"
						color={theme.colors.primary.header}
					>
						“
					</Typography>
					<Typography
						variant="h3"
						type="secondary"
						css={css`
							display: inline-block;
							letter-spacing: -2%;
							vertical-align: middle;
						`}
					>
						{content.quote}
						<Typography
							variant="span"
							css={css`
								display: inline-block;
								padding-top: 5px;
								font-size: 37.5px;
								vertical-align: middle;
							`}
							size="xl"
							weight="bold"
							color={theme.colors.primary.header}
						>
							”
						</Typography>
					</Typography>
				</Typography>
				<Flex
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					css={css`
						margin-top: 40px;
						margin-bottom: 30px;
					`}
				>
					<FlexItem>
						<img
							src={content.imageUrl}
							alt={content.author}
							css={css`
								width: 60px;
								height: auto;
								border-radius: 60px;
								display: block;
							`}
						/>
					</FlexItem>
					<FlexItem>
						<Typography
							variant="div"
							weight="bold"
							size="md"
							color={theme.colors.primary.header}
							css={css`
								padding-left: 10px;
								box-sizing: border-box;
							`}
						>
							{content.author}
						</Typography>
						<Typography
							variant="div"
							weight="bold"
							size="md"
							type="primary"
							css={css`
								padding-left: 10px;
								box-sizing: border-box;
							`}
						>
							{content.authorTitle}
						</Typography>
					</FlexItem>
				</Flex>
			</Media>
		</>
	);
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const sections: Sections[] = [];
	LeftRightContent[language].forEach((content) => {
		sections.push({
			title: content.title,
			cmp: <ESGLeftRightComponent content={content} />,
		});
	});
	sections.push({
		title: ESGQuoteContent[language].title,
		cmp: <ESGQuoteComponent content={ESGQuoteContent[language]} />,
	});
	sections.push({
		title: EmissionsIlluminatorContent[language].title,
		cmp: (
			<ESGImageBlurbWithLinkComponent
				imageUrl={EmissionsIlluminatorContent[language].imageUrl}
				title={EmissionsIlluminatorContent[language].title}
				blurb={EmissionsIlluminatorContent[language].blurb}
			/>
		),
	});
	return (
		<Layout>
			<ESGHeroComponent content={ESGHeroContent[language]} />
			<AllSections sections={sections} />
			<Media greaterThanOrEqual="lg">
				<ContactUsRefactor oneButtonVariant={true} background="primary" />
			</Media>
			<Media lessThan="lg">
				<ContactUsRefactor oneButtonVariant={true} withImage={false} background="primary" />
			</Media>
		</Layout>
	);
};
